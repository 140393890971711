import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { http } from '../../../../config';
import { getAllObservationsInDl } from '../../../observation/observation.services';
import DlMissionList from '../../pages/driver/dl-page/components/dl-mission-list/dl-mission-list';
import HandleExtraMission from '../../pages/driver/dl-page/components/handle-extra-mission';
import NoMissionObservations from '../../pages/driver/dl-page/components/no-mission-observations/no-mission-observations';
import './mission-list-all-obs.scss';

const MissionListAllObs = (props) => {
	const { setLoading, redirectUrl = '', showHeader = true, isTnMs = false, isEpe = false } = props;
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const [observations, setObservations] = useState([]);
	const queryKey = isTnMs
		? ['dlManoeuvres', driveLogId]
		: isEpe
		? ['dlEpes', driveLogId]
		: ['dlMissions', driveLogId];
	const { data: missions = [] } = useQuery({
		queryKey,
		queryFn: async () => {
			if (!driveLogId) return [];
			const endpoint = isTnMs ? 'manoeuvre' : isEpe ? 'epe' : 'mission';
			const response = await http.get(`/drive-log/${driveLogId}/${endpoint}`);
			return response.data.sort((a, b) => new Date(a.hour_start) - new Date(b.hour_start));
		},
		initialData: [],
	});

	const retrieveObservationsList = useCallback(() => {
		getAllObservationsInDl(driveLogId)
			.then((observationsRes) => {
				setObservations(observationsRes?.data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [driveLogId, setLoading]);

	useEffect(retrieveObservationsList, [driveLogId, retrieveObservationsList]);

	return (
		<>
			<div>
				<h2 className="mission-list-and-obs__title">
					<span>
						{isTnMs
							? t('dl:dl-details.missions-done.title-manoeuvre')
							: isEpe
							? t('dl:dl-details.missions-done.title-epe')
							: t('dl:dl-details.missions-done.title')}
					</span>
				</h2>
				{isTnMs || isEpe ? (
					<HandleExtraMission
						className="drive-log-detail__dl-list"
						missionsList={Array.isArray(missions) ? missions : []}
						observations={observations}
						readOnly={true}
						retrieveObservationsList={retrieveObservationsList}
						redirectUrl={redirectUrl}
						isTnMs={isTnMs}
					/>
				) : (
					<DlMissionList
						className="mission-list-and-obs__missions"
						missionsList={missions}
						observations={observations}
						retrieveObservationsList={retrieveObservationsList}
						readOnly={true}
						redirectUrl={redirectUrl}
						showHeader={showHeader}
					/>
				)}
				<NoMissionObservations
					className="mission-list-and-obs__no-mission-obs"
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					readOnly={true}
					redirectUrl={redirectUrl}
					isTnMs={isTnMs}
					isEpe={isEpe}
				/>
			</div>
		</>
	);
};

export default MissionListAllObs;
