import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DlObservationRow from '../dl-observation-row/dl-observation-row';
import './no-mission-observations.scss';

/**
 * Render the list of all observations related to no mission
 * @component
 * @param {Object} props
 * @param {array} props.observationsListNoMission - the observations list without mission array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 * @param {string} props.className - the className of the component
 * @param {boolean} props.readOnly - the boolean that define if the observation is on readonly mode
 * @param {boolean} props.uncompletedDl - the boolean that define if the driveLog is uncompleted
 */
const NoMissionObservations = (props) => {
	const { t } = useTranslation();
	const {
		observations = [],
		retrieveObservationsList,
		readOnly = false,
		redirectUrl = '',
		className,
		uncompletedDl,
		isTnMs = false,
		isEpe = false,
	} = props;

	const getText = () => {
		if (isTnMs) {
			return t('dl:dl-detail-page.observation-without-manoeuvre');
		}
		if (isEpe) {
			return t('dl:dl-detail-page.observation');
		}
		return t('dl:dl-detail-page.observation-without-mission');
	};

	const observationsListNoMission = observations?.filter((obs) => !obs.mission_id) || [];

	const generateObservationRow = (observation) => (
		<DlObservationRow
			observation={observation}
			key={observation.id}
			retrieveObservationsList={retrieveObservationsList}
			readOnly={readOnly}
			redirectUrl={redirectUrl}
			uncompletedDl={uncompletedDl}
		/>
	);

	return (
		observationsListNoMission?.length > 0 && (
			<div className={`no-mission-observations ${className}`}>
				<div className="no-mission-observations__title">{getText()}</div>
				<ul className="no-mission-observations__observation">
					{Array.isArray(observationsListNoMission) &&
						observationsListNoMission.map(generateObservationRow)}
				</ul>
			</div>
		)
	);
};

NoMissionObservations.propTypes = {
	observations: PropTypes.array.isRequired,
	retrieveObservationsList: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
};

export default NoMissionObservations;
