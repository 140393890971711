const carSelector = [
	'door',
	'doorSide',
	'location',
	'locationStart',
	'locationEnd',
	'stationLocation',
	'communicationMode',
	'equipment',
	'reasonOrderOrNotice',
	'reasonOrderOrNoticeSecurity',
	'reasonOrderOrNoticeTraffic',
	'period',
	'sens',
];

const carSelectorWithInput = [
	'car',
	'lane',
	'nightTrain',
	'pattern',
	'messageType',
	'reasonAlarmSignal',
];

const stationSearch = [
	'interStationStart',
	'interStationEnd',
	'post',
	'rfnStation',
	'station',
	'stationOn',
	'stationOff',
	'upstreamStation',
	'downstreamStation',
	'stationStartDriving',
	'stationEndDriving',
	'stationStartNoDriving',
	'stationEndNoDriving',
];

const trainStorageSearch = ['trainStorage', 'trainStorageChoice'];

const text = [
	'cabin',
	'cabinOne',
	'cabinTwo',
	'cassetteNoted',
	'cassetteInstalled',
	'drivingCab',
	'installation',
	'isolatedSecuritySystem',
	'laneStart',
	'laneEnd',
	'name',
	'ratpId',
	'orderNumber',
	'position',
	'signal',
	'trafficLane',
];

const comment = [
	'reason',
	'comment',
	'cabinsAndTrafficLanes',
	'stopReason',
	'addresseeName',
	'signalDetailComment',
];

const fileAttachment = ['signalDetailFiles'];

const yesNo = [
	'remoteReset',
	'trainDriving',
	'realizedManeuvers',
	'signalTransmission',
	'crossingNFSignal',
	'generalCall',
];

const radio = ['garageAction', 'pcc', 'authorizationType'];

const dateTime = ['dateTime', 'dateTimeStart', 'dateTimeEnd'];

const delay = ['delay', 'duration'];

const checkbox = ['manualDrive'];

const reminderText = ['exploitationNote'];

export {
	carSelector,
	carSelectorWithInput,
	checkbox,
	comment,
	dateTime,
	delay,
	fileAttachment,
	radio,
	reminderText,
	stationSearch,
	text,
	trainStorageSearch,
	yesNo,
};
