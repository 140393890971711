import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../../../core';

/*
	Modal to display when the 'Delete' button is clicked on a epe Item
 */
const DeleteEpeModal = ({ showModal, setShowModal, handleDeleteEpe, handleCancelDeleteEpe }) => {
	const { t } = useTranslation();

	const modalText = t('dl:dl-detail-page.delete-epe-popup');

	const closeCallback = () => {
		setShowModal(false);
	};

	return (
		showModal && (
			<Modal
				type="confirm"
				title={modalText}
				confirmCallback={handleDeleteEpe}
				cancelCallback={handleCancelDeleteEpe}
				confirmText={t('core:modal.yes')}
				cancelText={t('core:modal.no')}
				closeCallback={closeCallback}
			/>
		)
	);
};

DeleteEpeModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	handleDeleteEpe: PropTypes.func,
	handleCancelDeleteEpe: PropTypes.func,
};

export default DeleteEpeModal;
