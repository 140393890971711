import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useManoeuvresQueries } from '../../../../../../../../../config/react-query/manoeuvre.query';
import { Button, Icon, ResponsiveText } from '../../../../../../../../core';
import DeleteManoeuvreModal from '../modals/delete-manoeuvre-modal/delete-manoeuvre-modal';
import DeleteManoeuvreWithObservationsModal from '../modals/delete-manoeuvre-with-observations/delete-manoeuvre-with-observations';
import './dl-delete-manoeuvre.scss';

/**
 * Renders delete mission button and popup confirmation
 * @param {Object} props
 * @param {String} props.className
 * @param {String} props.driveLogId
 * @param {String} props.missionCode
 * @param {String} props.updateDriveLogMissions
 */
const DlDeleteManoeuvre = (props) => {
	const { className = '', hasObservations = false, driveLogId = '', missionId = '' } = props;

	const { t } = useTranslation();

	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [showDeletePopupWithObservations, setShowDeletePopupWithObservations] = useState(false);
	const [manoeuvreToDelete, setManoeuvreToDelete] = useState();
	const { deleteManoeuvre } = useManoeuvresQueries();

	const handleShowDeletePopup = (id) => {
		if (hasObservations) setShowDeletePopupWithObservations(true);
		else setShowDeletePopup(true);
		setManoeuvreToDelete(id);
	};

	const handleDeleteManoeuvre = () => {
		deleteManoeuvre.mutate({ id: manoeuvreToDelete, driveLogId: driveLogId });
	};

	const handleCancelDeleteManoeuvre = () => {
		setShowDeletePopup(false);
		setManoeuvreToDelete(undefined);
	};

	return (
		<div className={`dl-mission-list__mission__delete ${className}`}>
			<Button
				onClick={() => handleShowDeletePopup(missionId)}
				className="dl-mission-list__mission__delete__btn"
			>
				<Icon name="close" className="dl-mission-list__mission__delete__icon" />
				<ResponsiveText
					text=""
					mobileText={t('dl:dl-detail-page.delete')}
					className="dl-mission-list__mission__delete__text"
				/>
			</Button>

			<DeleteManoeuvreModal
				showModal={showDeletePopup}
				setShowModal={setShowDeletePopup}
				handleDeleteManoeuvre={handleDeleteManoeuvre}
				handleCancelDeleteManoeuvre={handleCancelDeleteManoeuvre}
			/>
			<DeleteManoeuvreWithObservationsModal
				showModal={showDeletePopupWithObservations}
				setShowModal={setShowDeletePopupWithObservations}
			/>
		</div>
	);
};

DlDeleteManoeuvre.propTypes = {
	className: PropTypes.string,
	updateDriveLogMissions: PropTypes.func,
	driveLogId: PropTypes.string,
	missionId: PropTypes.string,
};

export default DlDeleteManoeuvre;
