import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { http } from '../../../../../config';
import localForage from '../../../../../config/local-forage';
import { DriverPage, Loader, PopupFullPage } from '../../../../core';
import { getAllObservationsInDl } from '../../../../observation/observation.services';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import { getDriveLogById } from '../../../drive-log.services';
import getStatusRedirection from '../../../utils/get-status-redirection';
import AddObservationWithoutMission from './components/add-observation-without-mission/add-observation-without-mission';
import DlAddManoeuvre from './components/dl-add-manoeuvre/dl-add-manoeuvre';
import DlAddMission from './components/dl-add-mission/dl-add-mission';
import DlAddReleve from './components/dl-add-releve/dl-add-releve';
import DlMissionList from './components/dl-mission-list/dl-mission-list';
import DocumentMenuButton from './components/document-menu-button/document-menu-button';
import EmptyDlList from './components/empty-dl-list/empty-dl-list';
import FinalizeDl from './components/finalize-dl/finalize-dl';
import FinalizeMessage from './components/finalize-message/finalize-message';
import HandleExtraMission from './components/handle-extra-mission';
import NoMissionObservations from './components/no-mission-observations/no-mission-observations';
import './dl-page.scss';

/**
 * Renders a single drive log page
 */
const DlPage = (props) => {
	const { overrideRoles, params = {} } = props;
	const { redirectUrl = '' } = params;

	const { id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { user = {} } = useContext(AuthContext);
	const [dailyDriveLog, setDailyDriveLog] = useState({});
	const [observations, setObservations] = useState([]);
	const [uncompletedDl, setUncompletedDl] = useState(false);
	const [emptyTrainComposition, setEmptyTrainComposition] = useState(false);
	const [isTnMs, setIsTnMs] = useState(false);
	const [isEpe, setIsEpe] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const queryKey = isTnMs ? ['dlManoeuvres', id] : isEpe ? ['dlEpes', id] : ['dlMissions', id];

	const { data: missions = [], status } = useQuery({
		queryKey,
		queryFn: async () => {
			if (!id) return [];
			const endpoint = isTnMs ? 'manoeuvre' : isEpe ? 'epe' : 'mission';
			const response = await http.get(`/drive-log/${id}/${endpoint}`);
			return response.data.sort((a, b) => new Date(a.hour_start) - new Date(b.hour_start));
		},
		initialData: [],
	});
	const { data: worksheetImage } = useQuery({
		queryKey: ['dlWorksheet', id],
		queryFn: async () => {
			if (!id) return null;
			const worksheet = await localForage.getItem(`dl-${id}-worksheet`);
			if (!worksheet || !worksheet?.data || worksheet?.data?.size === 0) {
				const response = await http.get(`/drive-log/${id}/work-sheet/image`, {
					responseType: 'blob',
				});
				if (response && response.data && response.data.size > 0)
					return URL.createObjectURL(response.data);
			} else if (worksheet.data) {
				const url = URL.createObjectURL(worksheet.data);
				return url;
			}
			return null;
		},
	});

	const getMessage = () => {
		const trainCompoMessage = 'empty-compo-message';
		const uncompletedDlMessage = 'finalize-message';
		if (uncompletedDl) {
			return uncompletedDlMessage;
		} else if (emptyTrainComposition) {
			return trainCompoMessage;
		} else {
			return '';
		}
	};

	const message = getMessage();

	const retrieveObservationsList = useCallback(() => {
		getAllObservationsInDl(id)
			.then((payload) => {
				setObservations(payload.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	useEffect(() => {
		retrieveObservationsList();
	}, [id, retrieveObservationsList]);

	// TODO Notification WIP
	// const checkNotificationNeed = useCallback((driveLog) => {
	// 	const { is_empty: isEmptyDl } = driveLog;
	// 	const isAlertAlreadyRefused = localStorage.getItem("dl-alert-asked");
	// 	if(!isNotificationActive && !isEmptyDl && isAlertAlreadyRefused !== driveLog.id) {
	// 		setShowNotificationModal(true);
	// 	}
	// }, [isNotificationActive]);

	const init = useCallback(() => {
		getDriveLogById(id)
			.then((payload) => {
				const driveLog = payload.data;
				getStatusRedirection(driveLog, navigate);
				// tmp disable tns/ms epe
				if (!!driveLog.type_log && 1 === 2) {
					driveLog.type_log === 'epe' ? setIsEpe(true) : setIsTnMs(true);
				}
				setDailyDriveLog(driveLog);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line
	}, [id, navigate]);

	const checkAuthorization = () => {
		if (!!dailyDriveLog?.user_id && dailyDriveLog?.user_id !== user.id) {
			navigate('/');
		}
	};

	useEffect(() => {
		init();
	}, [id, init]);

	useEffect(checkAuthorization, [dailyDriveLog, navigate, user?.id]);

	const generateMissionList = () => {
		if (status === 'Loading') return <div className={'dbl-spinner'} />;
		const isMissionListEmpty = !missions || (Array.isArray(missions) && missions.length === 0);
		return isMissionListEmpty ? (
			<EmptyDlList
				className="drive-log-detail__dl-list"
				driveLogId={id}
				observations={observations}
				retrieveObservationsList={retrieveObservationsList}
				uncompletedDl={uncompletedDl}
				setUncompletedDl={setUncompletedDl}
				redirectUrl={redirectUrl}
				isTnMs={isTnMs}
				isEpe={isEpe}
			/>
		) : (
			<>
				{message && <FinalizeMessage message={message} />}
				{isTnMs || isEpe ? (
					<HandleExtraMission
						className="drive-log-detail__dl-list"
						missionsList={Array.isArray(missions) ? missions : []}
						observations={observations}
						retrieveObservationsList={retrieveObservationsList}
						uncompletedDl={uncompletedDl}
						redirectUrl={redirectUrl}
						isTnMs={isTnMs}
					/>
				) : (
					<DlMissionList
						className="drive-log-detail__dl-list"
						missionsList={Array.isArray(missions) ? missions : []}
						observations={observations}
						retrieveObservationsList={retrieveObservationsList}
						uncompletedDl={uncompletedDl}
						setUncompletedDl={setUncompletedDl}
						emptyTrainComposition={emptyTrainComposition}
						setEmptyTrainComposition={setEmptyTrainComposition}
						redirectUrl={redirectUrl}
						dailyDriveLog={dailyDriveLog}
						worksheetImageUrl={worksheetImage}
					/>
				)}
				<NoMissionObservations
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					uncompletedDl={uncompletedDl}
					redirectUrl={redirectUrl}
					isTnMs={isTnMs}
					isEpe={isEpe}
				/>

				<div className="drive-log-detail__actions">
					{isTnMs ? (
						<DlAddManoeuvre redirectUrl={redirectUrl} />
					) : isEpe ? (
						<DlAddReleve redirectUrl={redirectUrl} />
					) : (
						<DlAddMission displayButtonForm={true} redirectUrl={redirectUrl} />
					)}
					<AddObservationWithoutMission isTnMs={isTnMs} driveLogId={id} redirectUrl={redirectUrl} />
					<FinalizeDl
						driveLogId={id}
						missionsList={Array.isArray(missions) ? missions : []}
						observations={observations}
						setUncompletedDl={setUncompletedDl}
						redirectUrl={redirectUrl}
						disableComposition={isTnMs || isEpe}
					/>
				</div>
			</>
		);
	};

	if (redirectUrl === '/drive-log') {
		return (
			<DriverPage className="drive-log-detail" overrideRoles={overrideRoles}>
				<Loader isLoading={isLoading}>
					{generateMissionList()}
					<DocumentMenuButton driveLogId={id} />
				</Loader>
				{/* TODO Notification WIP */}
				{/*{showNotificationModal &&*/}
				{/*	<NotificationModal*/}
				{/*		showModal={showNotificationModal}*/}
				{/*		toggleModal={setShowNotificationModal}*/}
				{/*		cancelCallback={() => localStorage.setItem("dl-alert-asked", dailyDriveLog.id)}*/}
				{/*		confirmCallback={() => localStorage.setItem("dl-alert-asked", dailyDriveLog.id)}*/}
				{/*	/>*/}
				{/*}*/}
			</DriverPage>
		);
	}

	return (
		<PopupFullPage
			className="edit-dl-page"
			title={t('dl:dl-edit-page.title')}
			backLink={'/drive-logs/returned'}
		>
			{generateMissionList()}
		</PopupFullPage>
	);
};

export default DlPage;
