import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLine } from '../../../observation/custom-hooks/observation-custom-hooks';
import { getReferentialList } from '../../core.services';
import { SearchField } from '../../index';

/**
 * Renders a <SearchStationReferential /> component
 * @param {Object} props
 * @param {String} props.referential station referential: station, train-storage, office-station
 * @param {String} props.className class for search station component
 * @param {Object} props.selectedStation the selected station
 * @param {function} props.setSelectedStation setter function for selected station
 * @param {boolean} props.hasError form has error or not
 * @return {JSX.Element} <SearchStationReferential /> component
 */
const SearchStationReferential = (props) => {
	const {
		referential,
		className = '',
		selectedStation,
		setSelectedStation,
		hasError,
		placeholder = '',
	} = props;
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const type = searchParams.get('type');
	const { id: driveLogId } = useParams();

	const [stationsList, setStationsList] = useState([]);
	const [line, lineLoading] = useLine();

	useEffect(() => {
		getReferentialList(referential).then((res) => {
			setStationsList(res.data);
		});
	}, [referential, driveLogId]);

	if (lineLoading) {
		return null;
	}

	return (
		<SearchField
			className={className}
			searchList={stationsList}
			line={line}
			selectedOption={selectedStation}
			onSelect={setSelectedStation}
			placeholder={placeholder}
			hasError={hasError}
			isTnMs={type && type !== 'epe'}
		/>
	);
};

SearchStationReferential.propTypes = {
	referential: PropTypes.oneOf(['station', 'train-storage', 'office-station']).isRequired,
	className: PropTypes.string,
	selectedStation: PropTypes.object,
	setSelectedStation: PropTypes.func,
	hasError: PropTypes.bool,
};

export default SearchStationReferential;
