import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { DateUtils, Icon, ResponsiveText } from '../../../../../../../../core';
import MissionObservations from '../../../mission-observations/mission-observations';
import DlDeleteEpe from '../dl-delete-epe/dl-delete-epe';

/**
 * Component for display the mission list
 *
 * @component
 * @param {Object} props
 * @param {array} props.missionList - the missions array
 * @param {string} props.className - the class name
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 * @param {object} props.missionData - the mission data object
 * @param {boolean} props.readOnly - the boolean that define if the observation is on readonly mode
 * @param {boolean} props.uncompletedDl - the boolean that define if the driveLog is uncompleted
 */
const DlEpeRow = (props) => {
	const {
		observations,
		retrieveObservationsList,
		missionData,
		readOnly = false,
		redirectUrl = '',
		uncompletedDl,
	} = props;
	const { t } = useTranslation();
	const { formatHourMinutes } = DateUtils;
	const { id: driveLogId } = useParams();
	const {
		id,
		code,
		train_number,
		station_start,
		cassette_noted,
		cassette_installed,
		sens,
		period,
		hour,
	} = missionData;
	const redirect = `${redirectUrl}/${driveLogId}/mission/${id}/epe/edit?type=epe`;

	const missionReadOnly = (moment, station, hour) => (
		<div className={'header-category faisceau'}>
			<div className={`dl-mission-list__epe__${moment}__station`}>{station}</div>
			<div className={`dl-mission-list__epe__${moment}__modify`}>
				<div className={`dl-mission-list__epe__${moment}__modify__text`}>
					{formatHourMinutes(hour, ':')}
				</div>
			</div>
		</div>
	);

	const missionEditable = (moment, station, hour, redirection = null) => (
		<div className={'header-category faisceau'}>
			<Link to={redirection}>
				<div className={`dl-mission-list__epe__${moment}__station`}>{station}</div>
				<div className={`dl-mission-list__epe__${moment}__modify`}>
					<div className={`dl-mission-list__epe__${moment}__modify__text`}>
						{formatHourMinutes(hour, ':')}
					</div>
					<div className={`dl-mission-list__epe__${moment}__modify__link`}>
						<ResponsiveText
							text={t('dl:dl-detail-page.edit')}
							mobileText={<Icon name="pen" className="modify__link__icon" />}
						/>
					</div>
				</div>
			</Link>
		</div>
	);

	return (
		<li key={id} className="list-obs">
			{!readOnly
				? missionEditable('start', station_start, hour, redirect)
				: missionReadOnly('start', station_start, hour)}
			<div className="header-category vals">{train_number}</div>
			<div className="header-category vals">{cassette_noted}</div>
			<div className="header-category vals">{cassette_installed}</div>
			<div className="header-category vals">{sens}</div>
			<div className="header-category vals">{period}</div>
			{!readOnly && (
				<DlDeleteEpe
					hasObservations={observations.filter((obs) => obs.mission_id === id).length > 0}
					driveLogId={driveLogId}
					missionCode={code}
					missionId={id}
				/>
			)}

			<MissionObservations
				retrieveObservationsList={retrieveObservationsList}
				observations={observations}
				missionId={id}
				readOnly={readOnly}
				redirectUrl={redirectUrl}
				uncompletedDl={uncompletedDl}
			/>
		</li>
	);
};

DlEpeRow.propTypes = {
	missionList: PropTypes.array,
	className: PropTypes.string,
	observations: PropTypes.array,
	retrieveObservationsList: PropTypes.func,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
	emptyTrainComposition: PropTypes.bool,
	setEmptyTrainComposition: PropTypes.func,
};

export default DlEpeRow;
