import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../custom-hooks/use-debounce';
import useOnClickOutside from '../../../custom-hooks/use-on-click-outside';
import stripUnnecessaryCharacters from '../../../utils/strip-unnecessary-characters/strip-unnecessary-characters';
import Icon from '../../icon-svg/icon';
import InputDropdown from '../input-dropdown/input-dropdown';
import './search-field.scss';

/**
 * @typedef {Object} SearchOption
 * @Property {String} line - attachment line
 * @Property {String} name - station name
 * @Property {String} key - station key (name without diacritics, hyphens or underscores)
 */

/**
 * Renders a <SearchField /> component
 * @param {Object} props
 * @param {string} props.className - class name for the component
 * @param {Array<SearchOption>} props.searchList - list of options for the drop-down search
 * @param {String} props.line - line associated with the drive log
 * @param {SearchOption} props.selectedOption - the selected option
 * @param {function} props.onSelect - handler to select station
 * @param {boolean} props.hasError - form error boolean
 */
const SearchField = (props) => {
	const {
		className = '',
		searchList,
		line,
		selectedOption,
		onSelect,
		hasError,
		placeholder = '',
		disabled = false,
		isTnMs = false,
	} = props;
	const searchRef = useRef();

	const [search, setSearch] = useState('');
	const [results, setResults] = useState([]);
	const [error, setError] = useState(false);

	useOnClickOutside(searchRef, () => setResults([]));
	const debouncedSearch = stripUnnecessaryCharacters(useDebounce(search, 500));

	useEffect(() => {
		/**
		 * find stations that match the search string and the current line and return first 5 results
		 * @param string
		 * @return {*[]}
		 */
		const findStations = (string) => {
			// filter stations that match search string and current line
			const filteredStations = searchList.filter((station) => {
				const { key, line: stationLine } = station;
				// Remove the current line filter if type is true
				return (isTnMs || line === '-1' || stationLine === line) && key.includes(string);
			});

			// filter unique stations only
			const filteredUniqueStations = filteredStations.reduce((acc, station) => {
				const foundItem = acc.find((el) => el.key === station.key);
				if (!foundItem) {
					acc.push(station);
				}
				return acc;
			}, []);

			return filteredUniqueStations.slice(0, 5);
		};

		// search list has elements
		const searchListPopulated = searchList && searchList.length > 0;

		// search input has more than 3 characters
		const searchThreeCharacters = debouncedSearch && debouncedSearch.length >= 3;

		//can search when search list has elements, search input has more than 3 characters typed, and there's no options selected
		const canSearch = searchListPopulated && searchThreeCharacters && !selectedOption?.key;

		if (canSearch) {
			const stationsResults = findStations(debouncedSearch);
			if (!stationsResults || stationsResults.length === 0) {
				setError(true);
			}

			setResults(stationsResults);
		} else {
			setResults([]);
			setError(false);
		}
	}, [debouncedSearch, searchList, selectedOption, line, isTnMs]);

	const handleSearch = (newSearch) => {
		setSearch(newSearch);
		setError(!newSearch || newSearch.length === 0);
	};

	const handleSelect = (selectedOptionKey) => {
		const findStation = searchList.find(({ key }) => key === selectedOptionKey);
		setSearch('');
		setError(false);
		onSelect(findStation);
	};

	const showSelectedStation = () => {
		const { name: selectedOptionName } = selectedOption;
		return (
			<div className="search-field__selected">
				{selectedOptionName}
				<Icon
					aria-label="Close"
					className="search-field__selected__icon"
					name="close"
					role="button"
					onClick={() => (disabled ? {} : handleSelect())}
				/>
			</div>
		);
	};

	const showDropdown = () => (
		<InputDropdown
			value={search}
			placeholder={placeholder}
			onChange={handleSearch}
			results={results}
			onSelect={handleSelect}
			hasError={error || hasError}
		/>
	);

	const showSearchField = () => {
		// if an option is selected, display the selected option as a tag
		if (selectedOption?.key) {
			return showSelectedStation();
		}

		// if no option selected, then display an input with dropdown
		return showDropdown();
	};

	return (
		<div className={`search-field ${className}`} ref={searchRef}>
			{showSearchField()}
		</div>
	);
};

SearchField.propTypes = {
	className: PropTypes.string,
	searchList: PropTypes.array,
	line: PropTypes.string,
	selectedOption: PropTypes.object,
	onSelect: PropTypes.func,
	hasError: PropTypes.bool,
};

export default SearchField;
