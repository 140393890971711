import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const DlAddManoeuvre = (props) => {
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const { redirectUrl = '' } = props;
	const addManoeuvrePath = `${redirectUrl}/${driveLogId}/observation/add-move?type=tnms`;

	return (
		<button className="dl-add-mission-button-form button button--outline">
			<Link to={addManoeuvrePath}>{t('dl:dl-detail-page.add-manoeuvre')}</Link>
		</button>
	);
};

DlAddManoeuvre.propTypes = {
	buttonForm: PropTypes.bool,
};

export default DlAddManoeuvre;
