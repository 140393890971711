import { RegexUtils } from '../../../core';
import { regexValidator } from '../../components/observation-form/observation-validators';
import { setDateTimeWithService } from '../../observation.services';

const epe = [
	{
		fieldName: 'cabinAndFaisceau',
		mandatory: true,
		group: [
			{
				fieldName: 'cabin',
				validator: regexValidator(RegexUtils.alphaNumericAndSpecialCharacters),
				mandatory: true,
			},
			{
				fieldName: 'trainStorageChoice',
				mandatory: true,
			},
		],
	},
	{
		fieldName: 'cassette',
		mandatory: true,
		group: [
			{
				fieldName: 'cassetteNoted',
				validator: regexValidator(RegexUtils.numeric),
				mandatory: true,
			},
			{
				fieldName: 'cassetteInstalled',
				validator: regexValidator(RegexUtils.numeric),
				mandatory: true,
			},
		],
	},
	{
		fieldName: 'sens',
		mandatory: true,
	},
	{
		fieldName: 'period',
		mandatory: true,
	},
	{
		fieldName: 'dateTime',
		mandatory: true,
		defaultValue: ({ driveLog }) =>
			setDateTimeWithService(driveLog?.realized_date_start, new Date()),
	},
];

export default epe;
