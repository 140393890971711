import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import DlManoeuvreRow from './components/manoeuvre-row/dl-manoeuvre-row';
import './dl-manoeuvre-list.scss';

/**
 * Component for display the mission list
 *
 * @component
 * @param {Object} props
 * @param {array} props.missionList - the missions array
 * @param {string} props.className - the class name
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 */
const DlManoeuvreList = (props) => {
	const { t } = useTranslation();
	const {
		className = '',
		observations,
		retrieveObservationsList,
		readOnly = false,
		redirectUrl = '',
		uncompletedDl,
		setUncompletedDl,
		missionsList = [],
		dailyDriveLog = {},
	} = props;

	const generateMissionHeaders = () => {
		return (
			<li key={uuid()} className="header-list">
				<div className="header-category cab">{t('dl:dl-detail-page.cab_number')}</div>
				<div className="header-category track">{t('dl:dl-detail-page.start_track')}</div>

				<div className="header-category station start">{t('dl:dl-detail-page.start')}</div>
				<div className="header-category station end">{t('dl:dl-detail-page.end')}</div>
				<div className="header-category track">{t('dl:dl-detail-page.road_track')}</div>
				<div className="header-category track">{t('dl:dl-detail-page.end_track')}</div>

				{!readOnly && (
					<div className="header-category actions">{t('dl:dl-detail-page.actions')}</div>
				)}
			</li>
		);
	};

	const generateMissionLine = (missionData) => {
		return (
			<DlManoeuvreRow
				dailyDriveLog={dailyDriveLog}
				observations={observations}
				retrieveObservationsList={retrieveObservationsList}
				missionData={missionData}
				key={uuid()}
				readOnly={readOnly}
				uncompletedDl={uncompletedDl}
				setUncompletedDl={setUncompletedDl}
				redirectUrl={redirectUrl}
			/>
		);
	};

	return (
		<div className={`dl-mission-list ${className}`}>
			<div className="dl-mission-list__missions">
				<ul>
					{generateMissionHeaders()}
					{Array.isArray(missionsList) && missionsList.map(generateMissionLine)}
				</ul>
			</div>
		</div>
	);
};

DlManoeuvreList.propTypes = {
	className: PropTypes.string,
	observations: PropTypes.array,
	retrieveObservationsList: PropTypes.func,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
	setUncompletedDl: PropTypes.func,
	emptyTrainComposition: PropTypes.bool,
	setEmptyTrainComposition: PropTypes.func,
};

export default DlManoeuvreList;
