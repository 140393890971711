import '../dl-page.scss';
import DlEpeList from './dl-epe-list/dl-epe-list';
import DlManoeuvreList from './dl-manoeuvre-list/dl-manoeuvre-list';

const HandleExtraMission = ({
	missionsList,
	observations,
	retrieveObservationsList,
	uncompletedDl,
	redirectUrl,
	readOnly,
	isTnMs,
}) => {
	return (
		<>
			{isTnMs ? (
				<DlManoeuvreList
					className="drive-log-detail__dl-list"
					missionsList={missionsList}
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					uncompletedDl={uncompletedDl}
					redirectUrl={redirectUrl}
					readOnly={readOnly}
				/>
			) : (
				<DlEpeList
					className="drive-log-detail__dl-list"
					missionsList={missionsList}
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					uncompletedDl={uncompletedDl}
					redirectUrl={redirectUrl}
					readOnly={readOnly}
				/>
			)}
		</>
	);
};

export default HandleExtraMission;
