import {
	addDays,
	formatISO,
	getHours,
	getMinutes,
	getSeconds,
	isBefore,
	isDate,
	parseISO,
	set,
	setHours,
	setMinutes,
	setSeconds,
	startOfDay,
} from 'date-fns';
import { v4 as uuid } from 'uuid';
import { dispatchGetRequest, dispatchPostRequest, http } from '../../config';

/**
 * add observation to drive log
 * @param {string} driveLogId - drive log uuid
 * @param {Object} observation - observation content
 * @param {boolean} draft - save observation as draft
 * @returns {Promise} response
 */
const addObservation = (driveLogId, observation, draft = false) => {
	const generateId = uuid();
	return dispatchPostRequest(
		`dl-${driveLogId}-observations`,
		`/drive-log/${driveLogId}/observation`,
		{
			...observation,
			id: generateId,
			drive_log_id: driveLogId,
			draft,
		},
	);
};

const extractLocation = (location) => {
	switch (location.value) {
		case 'Faisceau':
			return { val: location.children.trainStorageChoice, val2: null };
		case 'Gare':
			return { val: location.children.station, val2: null };
		case 'Intergare':
			return { val: location.children.interStationStart, val2: location.children.interStationEnd };
		default:
			return { val: null, val2: null };
	}
};

const sendManoeuvre = (driveLogId, content, id, creation_date, manoeuvre, action) => {
	const {
		drivingCab,
		dateTimeStart,
		dateTimeEnd,
		locationStart,
		locationEnd,
		laneStart,
		laneEnd,
		trafficLane,
	} = content;
	const generateId = uuid();
	const start = extractLocation(locationStart);
	const end = extractLocation(locationEnd);
	const manoeuvreData = {
		id: id ? id : generateId,
		train_number: drivingCab,
		hour_start: dateTimeStart,
		hour_end: dateTimeEnd,
		created_at: creation_date,
		station_start: start.val.name,
		station_start_trigram: start.val.trigram,
		station_start_bigram: start.val.bigram,
		station_end: end.val.name,
		station_end_trigram: end.val.trigram,
		station_end_bigram: end.val.bigram,
		track_start: laneStart,
		track_end: laneEnd,
		traffic_track: trafficLane,
	};

	// Ajouter conditionnellement les propriétés de val2 si elles existent
	if (start.val2) {
		manoeuvreData.station_start_2 = start.val2.name;
		manoeuvreData.station_start_trigram_2 = start.val2.trigram;
		manoeuvreData.station_start_bigram_2 = start.val2.bigram;
	}

	if (end.val2) {
		manoeuvreData.station_end_2 = end.val2.name;
		manoeuvreData.station_end_trigram_2 = end.val2.trigram;
		manoeuvreData.station_end_bigram_2 = end.val2.bigram;
	}
	manoeuvre.mutate({
		driveLogId,
		manoeuvreId: id,
		values: manoeuvreData,
		action,
	});
};

const sendEpe = (driveLogId, content, id, creation_date, epe, action) => {
	const {
		cabin,
		trainStorageChoice,
		cassetteNoted,
		cassetteInstalled,
		sens,
		period,
		dateTime,
		comment = null,
	} = content;
	const generateId = uuid();
	const epeData = {
		id: id ? id : generateId,
		train_number: cabin,
		station_start: trainStorageChoice.name,
		hour: dateTime,
		cassette_noted: cassetteNoted,
		cassette_installed: cassetteInstalled,
		sens,
		period,
		creation_date,
		comment,
	};
	epe.mutate({
		driveLogId,
		epeId: id,
		values: epeData,
		action,
	});
};

/**
 * get all observations in a given drive-log
 * @param {string} driveLogId - drive log uuid
 * @returns {Promise<Array>} an array of all observations
 */
const getAllObservationsInDl = (driveLogId) => {
	return dispatchGetRequest(
		`dl-${driveLogId}-observations`,
		`/drive-log/${driveLogId}/observation`,
	);
};

/**
 * get all observations in Dl with "trigger_automatic_stop" type with a default void action queryparam
 * @param {string} driveLogId - drive log uuid
 * @param queryParam
 * @returns {Promise<Array>} an array of all observations for a specific type
 */
const getAllObservationsInDlByType = (driveLogId, queryParam = '') => {
	return dispatchGetRequest(
		`dl-${driveLogId}-observations`,
		`/drive-log/${driveLogId}/observation?action=${queryParam}`,
	);
};

/**
 * get an observation in a given drive-log
 * @param {string} driveLogId - drive log id
 * @param {string} observationId - observation id
 * @returns {Promise<Array>} an array of the observation
 */
const getObservationById = (driveLogId, observationId) => {
	return http.get(`/drive-log/${driveLogId}/observation/${observationId}`);
};

/**
 * update an observation in a given drive-log
 * @param {string} driveLogId - drive log id
 * @param {string} observationId - observation id
 * @param {Object} observation - the updated observation
 * @param {boolean} draft - save observation as draft
 * @returns {Promise<Object>} response
 */
const updateObservationById = (driveLogId, observationId, observation, draft) => {
	return http.put(`/drive-log/${driveLogId}/observation/${observationId}`, {
		...observation,
		draft,
	});
};

/**
 * delete an observation in a given drive-log
 * @param {string} driveLogId - drive log uuid
 * @param {string} observationId - the observation uuid
 * @returns {Promise} response
 */
const deleteObservation = (driveLogId, observationId) => {
	return http.delete(`/drive-log/${driveLogId}/observation/${observationId}`);
};

/***
 * @param startServiceDateTime {string}
 * @param startOfDayHour {number}
 * @param currentDate {Date}
 * @param updateType {string} - "hours" or "minutes" or "seconds" ("none" if no update)
 * @param updateValue {number}
 */
const setDateTimeWithService = (
	startServiceDateTime = formatISO(new Date()),
	startOfDayHour = undefined,
	currentDate = null,
	updateType = 'none',
	updateValue = 0,
) => {
	let formatStartServiceDateTime = isDate(startServiceDateTime)
		? startServiceDateTime
		: parseISO(startServiceDateTime);
	if (startOfDayHour) {
		formatStartServiceDateTime = set(formatStartServiceDateTime, {
			hours: getHours(startOfDayHour),
			minutes: getMinutes(startOfDayHour),
			seconds: getSeconds(startOfDayHour),
		});
	}

	const startDay = startOfDay(formatStartServiceDateTime);
	const startNextDay = addDays(startDay, 1);

	// add default values, otherwise observation fields don't initialize with current hour (returns undefined)
	let newTimeDay = formatStartServiceDateTime;
	let newTimeNextDay = addDays(formatStartServiceDateTime, 1);

	// Set old value for day of service and next day of service
	if (currentDate) {
		newTimeDay = setHours(startDay, getHours(currentDate));
		newTimeDay = setMinutes(newTimeDay, getMinutes(currentDate));
		newTimeDay = setSeconds(newTimeDay, getSeconds(currentDate));

		newTimeNextDay = setHours(startNextDay, getHours(currentDate));
		newTimeNextDay = setMinutes(newTimeNextDay, getMinutes(currentDate));
		newTimeNextDay = setSeconds(newTimeNextDay, getSeconds(currentDate));
	}

	// Update with time changed if required
	if (updateType !== 'none') {
		let setType;
		switch (updateType) {
			case 'hours':
				setType = setHours;
				break;
			case 'minutes':
				setType = setMinutes;
				break;
			default:
				setType = setSeconds;
		}
		newTimeDay = setType(currentDate ? newTimeDay : startDay, updateValue);
		newTimeNextDay = setType(currentDate ? newTimeNextDay : startNextDay, updateValue);
	}

	return isBefore(newTimeDay, setSeconds(formatStartServiceDateTime, 0))
		? newTimeNextDay
		: newTimeDay;
};

export {
	addObservation,
	deleteObservation,
	getAllObservationsInDl,
	getAllObservationsInDlByType,
	getObservationById,
	sendEpe,
	sendManoeuvre,
	setDateTimeWithService,
	updateObservationById,
};
