import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const DlAddReleve = (props) => {
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const { redirectUrl = '' } = props;
	const addManoeuvrePath = `${redirectUrl}/${driveLogId}/observation/add-epe?type=epe`;

	return (
		<button className="dl-add-mission-button-form button button--outline">
			<Link to={addManoeuvrePath}>{t('dl:dl-detail-page.add-releve')}</Link>
		</button>
	);
};

DlAddReleve.propTypes = {
	buttonForm: PropTypes.bool,
};

export default DlAddReleve;
