import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { http } from '../../../../config';
import { Loader } from '../../../core';
import AddObservation from '../add-observation/add-observation';

const updateLocation = (start, trigram, bigram, start_2, trigram_2, bigram_2) => {
	let type = 'Gare';
	let children = {};
	if (!trigram) {
		type = 'Faisceau';
		children = { trainStorageChoice: { name: start, key: start } };
	} else if (trigram && trigram_2) {
		type = 'Intergare';
		children = {
			interStationStart: { name: start, trigram: trigram, bigram: bigram, key: start },
			interStationEnd: { name: start_2, trigram: trigram_2, bigram: bigram_2, key: start_2 },
		};
	} else {
		children = {
			station: {
				name: start,
				trigram: trigram,
				bigram: bigram,
				key: start,
			},
		};
	}
	return { value: type, children: children };
};

/**
 * edit manoeuvre page
 * @returns {JSX.Element}
 * @constructor
 */
const EditManoeuvre = (props) => {
	const { params = {} } = props;
	const { id: driveLogId, manoeuvreId } = useParams();
	const { redirectUrl } = params;

	const { data: manoeuvre } = useQuery({
		queryKey: ['dlMission', manoeuvreId],
		queryFn: async () => {
			if (!manoeuvreId) return {};
			const response = await http.get(`/drive-log/${driveLogId}/manoeuvre/${manoeuvreId}`);
			if (!response.data) return {};
			const {
				id,
				hour_start,
				hour_end,
				train_number,
				track_start,
				track_end,
				traffic_track,
				station_start,
				station_start_trigram,
				station_start_bigram,
				station_start_2,
				station_start_trigram_2,
				station_start_bigram_2,
				station_end,
				station_end_trigram,
				station_end_bigram,
				station_end_2,
				station_end_trigram_2,
				station_end_bigram_2,
			} = response.data;
			const manoeuvre = {
				id: id,
				dateTimeStart: hour_start,
				dateTimeEnd: hour_end,
				drivingCab: train_number,
				laneStart: track_start,
				laneEnd: track_end,
				trafficLane: traffic_track,
				locationStart: updateLocation(
					station_start,
					station_start_trigram,
					station_start_bigram,
					station_start_2,
					station_start_trigram_2,
					station_start_bigram_2,
				),
				locationEnd: updateLocation(
					station_end,
					station_end_trigram,
					station_end_bigram,
					station_end_2,
					station_end_trigram_2,
					station_end_bigram_2,
				),
			};
			return manoeuvre;
		},
	});

	return (
		<Loader isLoading={!manoeuvre}>
			<AddObservation
				observationData={{ content: manoeuvre, observation_type: 'move' }}
				params={{ redirectUrl }}
			/>
		</Loader>
	);
};

export default EditManoeuvre;
