import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from '../axios';

const useDeleteManoeuvre = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: 'deleteManoeuvre',
		mutationFn: async ({ driveLogId, id }) => {
			await http.delete(`/drive-log/${driveLogId}/manoeuvre/${id}`);
		},
		onMutate: async (variables) => {
			queryClient.setQueryData(['dlManoeuvres', variables.driveLogId], (oldData) =>
				oldData.filter((item) => item.id !== variables.id),
			);
		},
	});
};

const useAddManoeuvre = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: 'addManoeuvre',
		mutationFn: async ({ driveLogId, values }) => {
			return http.post(`/drive-log/${driveLogId}/manoeuvre/`, values);
		},
		onMutate: async ({ driveLogId, values, action }) => {
			await queryClient.setQueryData(['dlManoeuvres', driveLogId], (oldData) => {
				if (!oldData) return [values];
				return [...oldData, { ...values }].sort(
					(a, b) => new Date(a.hour_start) - new Date(b.hour_start),
				);
			});
			if (action) action();
		},

		onSettled: (_, variables, __) => {
			// Invalider ou mettre à jour le cache après la suppression
			queryClient.invalidateQueries(['dlManoeuvres', variables.driveLogId]);
		},
	});
};

const useUpdateManoeuvre = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: 'updateManoeuvre',
		mutationFn: async ({ driveLogId, manoeuvreId, values }) => {
			return http.put(`/drive-log/${driveLogId}/manoeuvre/${manoeuvreId}`, { ...values });
		},
		onMutate: async ({ driveLogId, manoeuvreId, values, action }) => {
			const data = await queryClient.getQueryData(['dlManoeuvres', driveLogId]);
			if (data && Array.isArray(data) && data.length > 0) {
				await queryClient.setQueryData(['dlManoeuvres', driveLogId], () => {
					const val = data.map((item) => {
						if (item.id === manoeuvreId) {
							return { ...item, ...values };
						}
						return item;
					});
					return val;
				});
			}
			if (action) action();
		},
		onSettled: (_, variables, __) => {
			// Invalider ou mettre à jour le cache après la suppression
			queryClient.invalidateQueries(['dlManoeuvres', variables.driveLogId]);
		},
	});
};

export const useManoeuvresQueries = () => {
	const deleteManoeuvre = useDeleteManoeuvre();
	const addManoeuvre = useAddManoeuvre();
	const updateManoeuvre = useUpdateManoeuvre();

	return { addManoeuvre, updateManoeuvre, deleteManoeuvre };
};
