import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ResponsiveText } from '../../../../../../core';

const AddObservationWithoutMission = ({
	driveLogId,
	redirectUrl = '',
	isTnMs = false,
	isEpe = false,
}) => {
	const { t } = useTranslation();

	const getText = () => {
		if (isTnMs) {
			return t('dl:dl-detail-page.add-observation-without-manoeuvre');
		}
		if (isEpe) {
			return t('dl:dl-detail-page.add-observation');
		}
		return t('dl:dl-detail-page.add-observation-without-mission');
	};

	const getMobileText = () => {
		if (isTnMs) {
			return t('dl:dl-detail-page.add-observation-without-manoeuvre-short');
		}
		if (isEpe) {
			return t('dl:dl-detail-page.add-observation');
		}
		return t('dl:dl-detail-page.add-observation-without-mission-short');
	};

	return (
		<button className="add-observation-without-mission-button button button--outline">
			<Link
				className="add-observation-without-mission"
				to={`${redirectUrl}/${driveLogId}/add-observation?type=${
					isEpe ? 'epe' : isTnMs ? 'tnms' : ''
				}`}
			>
				<ResponsiveText text={getText()} mobileText={getMobileText()} />
			</Link>
		</button>
	);
};

AddObservationWithoutMission.propTypes = {
	driveLogId: PropTypes.string.isRequired,
	redirectUrl: PropTypes.string,
	isTnMs: PropTypes.bool,
	isEpe: PropTypes.bool,
};

export default AddObservationWithoutMission;
