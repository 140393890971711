import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../../../core';

/*
	Modal to display when the 'Delete' button is clicked on a manoeuvre Item
 */
const DeleteManoeuvreModal = ({
	showModal,
	setShowModal,
	handleDeleteManoeuvre,
	handleCancelDeleteManoeuvre,
}) => {
	const { t } = useTranslation();

	const modalText = t('dl:dl-detail-page.delete-manoeuvre-popup');

	const closeCallback = () => {
		setShowModal(false);
	};

	return (
		showModal && (
			<Modal
				type="confirm"
				title={modalText}
				confirmCallback={handleDeleteManoeuvre}
				cancelCallback={handleCancelDeleteManoeuvre}
				confirmText={t('core:modal.yes')}
				cancelText={t('core:modal.no')}
				closeCallback={closeCallback}
			/>
		)
	);
};

DeleteManoeuvreModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	handleDeleteManoeuvre: PropTypes.func,
	handleCancelDeleteManoeuvre: PropTypes.func,
};

export default DeleteManoeuvreModal;
