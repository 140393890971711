import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, DateUtils, Icon, ResponsiveText } from '../../../../../../../../core';
import MissionObservations from '../../../mission-observations/mission-observations';
import DlDeleteManoeuvre from '../dl-delete-manoeuvre/dl-delete-manoeuvre';

/**
 * Component for display the mission list
 *
 * @component
 * @param {Object} props
 * @param {array} props.missionList - the missions array
 * @param {string} props.className - the class name
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 * @param {object} props.missionData - the mission data object
 * @param {boolean} props.readOnly - the boolean that define if the observation is on readonly mode
 * @param {boolean} props.uncompletedDl - the boolean that define if the driveLog is uncompleted
 */
const DlManoeuvreRow = (props) => {
	const {
		observations,
		retrieveObservationsList,
		missionData,
		readOnly = false,
		redirectUrl = '',
		uncompletedDl,
	} = props;
	const { t } = useTranslation();
	const { formatHourMinutes } = DateUtils;
	const { id: driveLogId } = useParams();
	const {
		id,
		code,
		train_number,
		station_start,
		station_end,
		hour_start,
		hour_end,
		track_start,
		track_end,
		station_start_2,
		station_end_2,
		traffic_track,
	} = missionData;

	const linkToObsList = `${redirectUrl}/${driveLogId}/mission/${id}/add-observation-manoeuvre`;
	const redirect = `${redirectUrl}/${driveLogId}/mission/${id}/manoeuvre/edit?type=tnms`;

	const missionReadOnly = (moment, station, station_2, hour) => (
		<div className={`header-category station ${moment}`}>
			<div className={`dl-mission-list__manoeuvre__${moment}__station`}>
				{station}
				{station_2 && ` ⟷ ${station_2}`}
			</div>
			<div className={`dl-mission-list__manoeuvre__${moment}__modify`}>
				<div className={`dl-mission-list__manoeuvre__${moment}__modify__text`}>
					{formatHourMinutes(hour, ':')}
				</div>
			</div>
		</div>
	);

	const missionEditable = (moment, station, station_2, hour, redirection = null) => (
		<div className={`header-category station ${moment}`}>
			<Link to={redirection}>
				<div className={`dl-mission-list__manoeuvre__${moment}__station`}>
					{station}
					{station_2 && ` ⟷ ${station_2}`}
				</div>
				<div className={`dl-mission-list__manoeuvre__${moment}__modify`}>
					<div className={`dl-mission-list__manoeuvre__${moment}__modify__text`}>
						{formatHourMinutes(hour, ':')}
					</div>
					<div className={`dl-mission-list__manoeuvre__${moment}__modify__link`}>
						<ResponsiveText
							text={t('dl:dl-detail-page.edit')}
							mobileText={<Icon name="pen" className="modify__link__icon" />}
						/>
					</div>
				</div>
			</Link>
		</div>
	);

	return (
		<li key={id} className="list-obs">
			<div className="header-category cab">{train_number}</div>
			<div className="header-category track">{track_start}</div>
			{!readOnly
				? missionEditable('start', station_start, station_start_2, hour_start, redirect)
				: missionReadOnly('start', station_start, station_start_2, hour_start)}
			{!readOnly
				? missionEditable('end', station_end, station_end_2, hour_end, redirect)
				: missionReadOnly('end', station_end, station_end_2, hour_end)}
			<div className="header-category track">{traffic_track}</div>
			<div className="header-category track">{track_end}</div>
			{!readOnly && (
				<>
					<div className="header-category actions">
						<Link to={linkToObsList}>
							<ResponsiveText
								text={<Button light>{t('dl:dl-detail-page.add-observation')}</Button>}
								className={'add-obs'}
							/>
						</Link>
					</div>
					<DlDeleteManoeuvre
						hasObservations={observations.filter((obs) => obs.mission_id === id).length > 0}
						driveLogId={driveLogId}
						missionCode={code}
						missionId={id}
					/>
				</>
			)}

			<MissionObservations
				retrieveObservationsList={retrieveObservationsList}
				observations={observations}
				missionId={id}
				readOnly={readOnly}
				redirectUrl={redirectUrl}
				uncompletedDl={uncompletedDl}
			/>
		</li>
	);
};

DlManoeuvreRow.propTypes = {
	missionList: PropTypes.array,
	className: PropTypes.string,
	observations: PropTypes.array,
	retrieveObservationsList: PropTypes.func,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
	emptyTrainComposition: PropTypes.bool,
	setEmptyTrainComposition: PropTypes.func,
};

export default DlManoeuvreRow;
