import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import DlEpeRow from './components/epe-row/dl-epe-row';
import './dl-epe-list.scss';

/**
 * Component for display the mission list
 *
 * @component
 * @param {Object} props
 * @param {array} props.missionList - the missions array
 * @param {string} props.className - the class name
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 */
const DlEpeList = (props) => {
	const { t } = useTranslation();
	const {
		className = '',
		observations,
		retrieveObservationsList,
		readOnly = false,
		redirectUrl = '',
		uncompletedDl,
		setUncompletedDl,
		missionsList = [],
		dailyDriveLog = {},
	} = props;

	const generateMissionHeaders = () => {
		return (
			<li key={uuid()} className="header-list">
				<div className="header-category faisceau">{t('dl:dl-detail-page.faisceau')}</div>
				<div className="header-category vals">{t('dl:dl-detail-page.cab_number')}</div>
				<div className="header-category vals">{t('dl:dl-detail-page.cassette-noted')}</div>
				<div className="header-category vals">{t('dl:dl-detail-page.cassette-installed')}</div>
				<div className="header-category vals">{t('dl:dl-detail-page.sens')}</div>
				<div className="header-category vals">{t('dl:dl-detail-page.period')}</div>
			</li>
		);
	};

	const generateMissionLine = (missionData) => {
		return (
			<DlEpeRow
				dailyDriveLog={dailyDriveLog}
				observations={observations}
				retrieveObservationsList={retrieveObservationsList}
				missionData={missionData}
				key={uuid()}
				readOnly={readOnly}
				uncompletedDl={uncompletedDl}
				setUncompletedDl={setUncompletedDl}
				redirectUrl={redirectUrl}
			/>
		);
	};

	return (
		<div className={`dl-mission-list ${className}`}>
			<div className="dl-mission-list__missions">
				<ul>
					{generateMissionHeaders()}
					{Array.isArray(missionsList) && missionsList.map(generateMissionLine)}
				</ul>
			</div>
		</div>
	);
};

DlEpeList.propTypes = {
	className: PropTypes.string,
	observations: PropTypes.array,
	retrieveObservationsList: PropTypes.func,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
	setUncompletedDl: PropTypes.func,
	emptyTrainComposition: PropTypes.bool,
	setEmptyTrainComposition: PropTypes.func,
};

export default DlEpeList;
