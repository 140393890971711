import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DriveLogModal from '../../../attachment/components/drivelog-modal/drivelog-modal';
import PsgDrivelogModal from '../../../attachment/components/psg-drivelog-modal/psg-drivelog-modal';
import PsgOptionCard from '../../../attachment/components/psg-option-card/psg-option-card';
import { Button, PopupFullPage } from '../../../core';
import { AuthContext } from '../../../user/auth/context/auth-context';
import { DriveLogSubHeader } from '../../index';
import AttachmentCard from './attachment-card/attachment-card';
import './attachment-list.scss';

const attachments = [
	{
		line: 'B',
		code: 'DENC',
		name: 'Denfert-Rochereau',
		bigram: 'DR',
		referential_name: 'DENFERT CONDUITE',
		special_operation_attachment_code: '',
	},
	{
		line: 'B',
		code: 'MAPC',
		name: 'Massy - Palaiseau',
		bigram: 'MP',
		referential_name: 'MASSY PAL CONDUITE',
		special_operation_attachment_code: 'MAMS',
	},
	{
		line: 'A',
		code: 'NAPC',
		name: 'Nanterre-Préfecture',
		bigram: 'NP',
		referential_name: 'NANTERRE CONDUITE',
		special_operation_attachment_code: '',
	},
	{
		line: 'B',
		code: 'REMC',
		name: 'Saint-Rémy-lès-Chevreuse',
		bigram: 'SR',
		referential_name: 'SAINT REMY CONDUITE',
		special_operation_attachment_code: '',
	},
	{
		line: 'B',
		code: 'ROBC',
		name: 'Robinson',
		bigram: 'RO',
		referential_name: 'ROBINSON CONDUITE',
		special_operation_attachment_code: '',
	},
	{
		line: 'A',
		code: 'RUEC',
		name: 'Rueil-Malmaison',
		bigram: 'RM',
		referential_name: 'RUEIL CONDUITE',
		special_operation_attachment_code: 'RUMS',
	},
	{
		line: 'A',
		code: 'TNLA',
		name: 'Train de service ligne A',
		bigram: 'TN',
		referential_name: 'TX TRAINS SERVICE A',
		special_operation_attachment_code: '',
	},
	{
		line: 'B',
		code: 'TNLB',
		name: 'Train de service ligne B',
		bigram: 'TN',
		referential_name: 'TX TRAIN SERVICE TLB',
		special_operation_attachment_code: '',
	},
	{
		line: 'A',
		code: 'TORC',
		name: 'Torcy ',
		bigram: 'TM',
		referential_name: 'TORCY CONDUITE',
		special_operation_attachment_code: 'TOMS',
	},
	{
		line: 'A',
		code: 'VARC',
		name: 'La Varenne - Chennevières',
		bigram: 'LV',
		referential_name: 'LA VARENNE CONDUITE',
		special_operation_attachment_code: 'VAMS',
	},
];

// The <AttachmentList/> component is used for the creation of drivelog. Thus, it doesn't require the PSG attachment.
const AttachmentList = (props) => {
	const { t } = useTranslation();
	const {
		navigate,
		title = t('dl:dl-new-page.title'),
		showOptionPSG = false,
		// isEmpty = false,
	} = props;
	const [isFormValid, setIsFormValid] = useState(false);
	const [attachmentsFiltered, setAttachmentsFiltered] = useState('');
	const [attachment, setAttachment] = useState({});
	const [isPsg, setIsPsg] = useState(false);
	const [showPsgDriveLogModal, setShowPsgDriveLogModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const { user } = useContext(AuthContext) || {};
	const isAdminRole = ['admin', 'localAdmin'].includes(user.role);
	const isAmpPsg = user.attachment_code === 'BPSG';

	const toggleOptionPSG = () => {
		setIsPsg(!isPsg);
	};

	useEffect(() => {
		setIsFormValid(attachment && Object.keys(attachment).length > 0);
		if (!(isAdminRole || isAmpPsg)) {
			setAttachmentsFiltered(attachments.filter((a) => a.line === user.line));
		} else setAttachmentsFiltered(attachments);
	}, [user?.line, isAmpPsg, isAdminRole, attachment]);

	/**
	 * @function handleSelected - set the selectedCode state with the data retrieved from the children component
	 * @param {String} name - the attachment card code
	 */
	const handleClick = (value) => () => {
		const alreadySelected = value.code === attachment.code;
		setAttachment(alreadySelected ? {} : value);
	};

	const validateStep = (isTnMs = false) => {
		let type = null;
		if (isTnMs) type = attachment.bigram === 'TN' ? 'tn' : 'ms';
		const { name, line, bigram, code } = attachment;
		navigate({ line, attachmentBigram: bigram, attachmentName: name, isPsg, code, type });
	};

	const handleSubmit = () => {
		if (isPsg) setShowPsgDriveLogModal(!showPsgDriveLogModal);
		else validateStep(false);
		// tmp disable tnms / epe
		// else if (isEmpty) setShowModal(!showModal);
		// else validateStep(false);
	};

	const generateAttachmentCard = (value) => {
		const { code, name } = value;
		return (
			<AttachmentCard
				isSelected={attachment.code === code}
				handleClick={handleClick(value)}
				key={code}
				name={name}
			/>
		);
	};

	return (
		<PopupFullPage title={title}>
			<div className="attachment-list">
				<DriveLogSubHeader subHeader={t('dl:attachment-page:title')} />
				<ul className="attachment-list__content">
					{attachmentsFiltered && attachmentsFiltered.map(generateAttachmentCard)}
				</ul>
				{showOptionPSG && <PsgOptionCard isPsgActive={isPsg} onChange={toggleOptionPSG} />}
				<Button disabled={!isFormValid} className="attachment__button" onClick={handleSubmit}>
					{t('core:popup-full-page.button.validate')}
				</Button>
				<PsgDrivelogModal
					showModal={showPsgDriveLogModal}
					setShowPsgDriveLogModal={setShowPsgDriveLogModal}
					setIsPsg={setIsPsg}
					validateStep={validateStep}
				/>
				<DriveLogModal
					showModal={showModal}
					setShowModal={setShowModal}
					validateStep={validateStep}
				/>
			</div>
		</PopupFullPage>
	);
};

export default AttachmentList;
