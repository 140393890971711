import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import localForage from '../../../../../config/local-forage/index';
import { deleteMultipleAttentionAlerts } from '../../../../attention-alert/attention-alert.services';
import { Button, PopupFullPage } from '../../../../core';
import FeedbackMenuButton from '../../../../core/components/feedback/components/feedback-menu-button/menu-feedback-button';
import Icon from '../../../../core/components/icon-svg/icon';
import Modal from '../../../../core/components/modal/modal';
import { MATOMO_ACTIONS, MATOMO_CATEGORIES } from '../../../../core/matomo/matomo-constants';
import useMatomoTracker from '../../../../core/matomo/matomo-tracker';
import { NotificationContext } from '../../../../core/notification/notification-context';
import DlDetailsSubheader from '../../../components/dl-details-subheader/dl-details-subheader';
import SurveyList from '../../../components/survey-list/survey-list';
import { getDriveLogById, updateDriveLog } from '../../../drive-log.services';
import { MissionListAllObs, ServiceInfos } from '../../../index';
import getStatusRedirection from '../../../utils/get-status-redirection';
import DelaysTable from '../delay-list-page/components/delays-table/delays-table';
import './sign-dl-page.scss';

const SignDlPage = (props) => {
	const { params = {} } = props;
	const { redirectUrl = '' } = params;

	const { saveNotificationConfig, removeAlert } = useContext(NotificationContext);
	const { trackEvent } = useMatomoTracker();

	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const navigate = useNavigate();
	const [type, setType] = useState();

	const [dlToUpdate, setDlToUpdate] = useState({ id: driveLogId });
	const [currentDriveLog, setCurrentDriveLog] = useState({});
	const [loading, setLoading] = useState(true);
	const [displaySignModal, setDisplaySignModal] = useState(false);
	const [displayModifyServiceModal, setDisplayModifyServiceModal] = useState(false);
	const [animationCount, setAnimationCount] = useState(null);
	const animationInterval = React.createRef();
	const animationCountToWait = 3;

	const clearStationsStateFromCache = async () => {
		await localForage.iterate((_value, key) => {
			if (key.startsWith('stationsState')) {
				localForage.removeItem(key);
			}
		});
	};

	const signDl = useCallback(() => {
		getDriveLogById(driveLogId)
			.then((payload) => {
				saveNotificationConfig({ active: false, date: new Date() });
				removeAlert();
				deleteMultipleAttentionAlerts();
				clearStationsStateFromCache();
				const driveLog = payload.data;
				return updateDriveLog(driveLog, { action: 'sign' });
			})
			.then(() => {
				if (!currentDriveLog.is_psg) {
					trackEvent({
						category: MATOMO_CATEGORIES.DRIVELOG,
						action: MATOMO_ACTIONS.SIGN,
						value: 1,
					});
				}
				navigate(`${redirectUrl}/${driveLogId}/signed-dl`);
			})
			.catch((err) => {
				console.error(err);
			});
		// eslint-disable-next-line
	}, [driveLogId, currentDriveLog.is_psg, navigate, redirectUrl, trackEvent]);

	const init = useCallback(() => {
		getDriveLogById(driveLogId, { extended: true })
			.then((payload) => {
				const driveLog = payload.data;
				if (driveLog.type_log) setType(driveLog.type_log === 'epe' ? 'epe' : 'tnms');
				setCurrentDriveLog(driveLog);
				setLoading(false);
				getStatusRedirection(driveLog, navigate, redirectUrl);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [driveLogId, navigate, redirectUrl]);

	useEffect(init, [driveLogId, init]);

	const handleOpenSignModal = () => {
		setDisplaySignModal(true);
		setAnimationCount(0);
	};

	const handleCloseSignModal = () => {
		setDisplaySignModal(false);
		setAnimationCount(null);
	};

	const handleSignModal = () => {
		setDisplaySignModal(false);
		signDl();
	};

	const closeModifyServiceModal = () => {
		setDisplayModifyServiceModal(false);
	};

	useEffect(() => {
		animationInterval.current = setInterval(() => {
			if (animationCount === 3) {
				clearInterval(animationInterval.current);
				animationInterval.current = null;
			} else {
				setAnimationCount((c) => c + 1);
			}
		}, 1000);
	}, [animationCount, animationInterval]);

	useEffect(() => {
		return () => {
			if (animationInterval.current) {
				clearInterval(animationInterval.current);
			}
		};
	}, [animationInterval]);

	return (
		<PopupFullPage
			title={t('dl:signature-page.title')}
			className="signature-dl-page"
			backLink={`${redirectUrl}/${currentDriveLog.id}`}
		>
			<DlDetailsSubheader
				driveLog={currentDriveLog}
				displayBackLink={false}
				redirectUrl={redirectUrl}
			/>
			<div className="drive-log-details-driver__content drive-log-details-driver__content-sign">
				<ServiceInfos
					driveLog={currentDriveLog}
					loading={loading}
					setDisplayModifyServiceModal={setDisplayModifyServiceModal}
				/>
				<DelaysTable
					setDlToUpdate={setDlToUpdate}
					dlToUpdate={dlToUpdate}
					loading={loading}
					driveLog={currentDriveLog}
					readOnly={false}
					showTitle
					redirect={'sign'}
					redirectUrl={redirectUrl}
					loadDriveLog={init}
				/>
				<div className="drive-log-details-driver--missions">
					<MissionListAllObs
						loading={loading}
						setLoading={setLoading}
						showHeader={false}
						isTnMs={type === 'tnms'}
						isEpe={type === 'epe'}
					/>
				</div>
				<SurveyList showUpdateButton redirectUrl={redirectUrl} />
			</div>
			<div className={'signature-dl-page__button-group'}>
				<Link
					className={'button button--outline signature-dl-page__button-group__button'}
					to={`${redirectUrl}/${currentDriveLog.id}`}
				>
					{t('dl:signature-page.edit')}
				</Link>
				<Button className={''} onClick={handleOpenSignModal}>
					{t('dl:signature-page.validate')}
				</Button>
			</div>
			{displaySignModal && (
				<Modal type={'custom'}>
					<div className={'signature-page-sign-modal'}>
						<div onClick={handleCloseSignModal}>
							<Icon name="close" className="signature-page-sign-modal__close" />
						</div>
					</div>
					<div className={'signature-page-sign-modal__title'}>
						<p>{t('dl:signature-page.modal.title-1')}</p>
						<p>
							<span>{t('dl:signature-page.modal.title-2')}</span>
							<span className={'signature-page-sign-modal__title-warning'}>
								{t('dl:signature-page.modal.title-3')}
							</span>
						</p>
						<p className={'signature-page-sign-modal__title-warning'}>
							{t('dl:signature-page.modal.title-4')}
						</p>
					</div>
					<div className={'signature-page-sign-modal__button-group'}>
						<Button
							className={'signature-page-sign-modal__button'}
							onClick={handleCloseSignModal}
							outline
						>
							{t('dl:signature-page.modal.no')}
						</Button>
						<Button
							className={''}
							onClick={handleSignModal}
							disabled={animationCount !== animationCountToWait}
						>
							{t('dl:signature-page.modal.yes')}
						</Button>
					</div>
					<div className={'signature-page-sign-modal__instruction'}>
						{animationCountToWait - animationCount > 0 && (
							<p>
								<span>{t('dl:signature-page.modal.instruction-1')}</span>
								<span className={'signature-page-sign-modal__instruction-counter'}>
									{animationCountToWait - animationCount}
								</span>
								<span>{t('dl:signature-page.modal.instruction-2')}</span>
							</p>
						)}
						{animationCountToWait - animationCount === 0 && (
							<p>{t('dl:signature-page.modal.instruction-ok')}</p>
						)}
					</div>
				</Modal>
			)}
			{displayModifyServiceModal && (
				<Modal
					type="confirm"
					title={t('dl:signature-page.service-modal')}
					confirmCallback={() =>
						navigate(
							`${redirectUrl}/${driveLogId}/modify-service?action=signDl&backlink=${redirectUrl}/${driveLogId}/sign`,
						)
					}
					cancelCallback={closeModifyServiceModal}
					closeCallback={closeModifyServiceModal}
				/>
			)}
			<FeedbackMenuButton customPosition={true} />
		</PopupFullPage>
	);
};

export default SignDlPage;
