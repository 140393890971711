import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader, PopupFullPage } from '../../../../core';
import EmptySplash from '../../../../core/assets/images/empty-splash.svg';
import { useLine } from '../../../../observation/custom-hooks/observation-custom-hooks';
import { getPccNotices } from '../../../services';
import PccNoticeCard from './components/pcc-notice-card';
import './dl-pcc-notice-page.scss';

const REFRESH_PCC_NOTICE = 30000;

const DlPccNoticePage = () => {
	const { t } = useTranslation();
	const { id: driveLogId = '' } = useParams();
	const [line] = useLine();
	const [isLoading, setIsLoading] = useState(true);
	const [pccNoticesList, setPccNoticesList] = useState([]);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		let timeoutId;
		if (line) {
			const fetchPccNotices = async () => {
				setIsError(false);
				getPccNotices({ line })
					.then((response) => {
						setPccNoticesList(response?.data);
					})
					.catch((err) => {
						setIsError(true);
					})
					.finally(() => {
						setIsLoading(false);
					});

				timeoutId = setTimeout(fetchPccNotices, REFRESH_PCC_NOTICE);
			};

			fetchPccNotices();
		} else setIsLoading(false);

		return () => {
			if (timeoutId) clearTimeout(timeoutId);
		};
	}, [line]);

	const displayPccNoticeCard = (pccNotice) => {
		return <PccNoticeCard key={pccNotice.id} pccNotice={pccNotice} />;
	};

	const displayPccNoticesResult = () => {
		if (pccNoticesList?.length > 0) {
			return (
				<div className="dl-pcc-notice-page__pcc-notices">
					{pccNoticesList.map(displayPccNoticeCard)}
				</div>
			);
		} else
			return (
				<div className="dl-pcc-notice-page__no-result">
					<div
						className="no-result__searching-mouse-img"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
					<h1 className="no-result__text">
						{isError
							? t('dl:dl-documents.pcc-notice.no-batch')
							: t('dl:dl-documents.pcc-notice.no-result')}
					</h1>
				</div>
			);
	};

	return (
		<PopupFullPage
			className="dl-pcc-notice-page"
			title={t('dl:dl-documents.pcc-notice.title')}
			backLink={`/drive-log/${driveLogId}`}
		>
			<Loader isLoading={isLoading}>{displayPccNoticesResult()}</Loader>
		</PopupFullPage>
	);
};

export default DlPccNoticePage;
