import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from '../../../../core';
import './epe-modal.scss';

const EpeModal = (props) => {
	const { t } = useTranslation('at');
	const { showModal, setShowModal, navigate, serviceNumber = '', setServiceNumber } = props;

	const confirmCallback = () => {
		const trimmedServiceNumber = serviceNumber.replace(/^0+/, '');
		navigate({ serviceNumber: trimmedServiceNumber, type: 'epe' });
	};

	const denyCallback = () => {
		setServiceNumber('');
		cancelCallback();
	};

	const cancelCallback = () => {
		setShowModal(!showModal);
	};

	return (
		showModal && (
			<Modal type="custom">
				<button className="modal__icon" onClick={cancelCallback}>
					<Icon name="close" />
				</button>
				<div className="modal__container">
					<p className="modal__container__content">{t('dl:service-number.relevage-epe')}</p>
				</div>
				<div className="modal__container__button">
					<Button className="button--outline" onClick={denyCallback}>
						{t('modal.no')}
					</Button>
					<Button className="button" onClick={confirmCallback}>
						{t('modal.yes')}
					</Button>
				</div>
			</Modal>
		)
	);
};

export default EpeModal;
