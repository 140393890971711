import { RegexUtils } from '../../../core';
import {
	regexValidator,
	validateDelay,
	validateReason,
} from '../../components/observation-form/observation-validators';
import { setDateTimeWithService } from '../../observation.services';

const maneuver = [
	{
		fieldName: 'drivingCab',
		mandatory: true,
		validator: regexValidator(RegexUtils.fourDigitNumber),
	},
	{
		fieldName: 'locationStart',
		mandatory: true,
		children: [
			{
				fieldName: 'station',
				mandatory: true,
				parentValue: 'Gare',
			},
			{
				fieldName: 'interStationStart',
				mandatory: true,
				parentValue: 'Intergare',
			},
			{
				fieldName: 'interStationEnd',
				mandatory: true,
				parentValue: 'Intergare',
			},
			{
				fieldName: 'trainStorageChoice',
				mandatory: true,
				parentValue: 'Faisceau',
			},
		],
	},
	{
		fieldName: 'startValues',
		mandatory: true,
		group: [
			{
				fieldName: 'dateTimeStart',
				mandatory: true,
				defaultValue: ({ driveLog }) =>
					setDateTimeWithService(driveLog?.realized_date_start, new Date()),
			},
			{
				fieldName: 'laneStart',
				validator: regexValidator(RegexUtils.alphaNumericWithFifteenCharacters),
				mandatory: true,
			},
		],
	},
	{
		fieldName: 'locationEnd',
		mandatory: true,
		children: [
			{
				fieldName: 'station',
				mandatory: true,
				parentValue: 'Gare',
			},
			{
				fieldName: 'interStationStart',
				mandatory: true,
				parentValue: 'Intergare',
			},
			{
				fieldName: 'interStationEnd',
				mandatory: true,
				parentValue: 'Intergare',
			},
			{
				fieldName: 'trainStorageChoice',
				mandatory: true,
				parentValue: 'Faisceau',
			},
		],
	},
	{
		fieldName: 'endValues',
		mandatory: true,
		group: [
			{
				fieldName: 'dateTimeEnd',
				mandatory: true,
				defaultValue: ({ driveLog }) =>
					setDateTimeWithService(driveLog?.realized_date_start, new Date()),
			},
			{
				fieldName: 'laneEnd',
				validator: regexValidator(RegexUtils.alphaNumericWithFifteenCharacters),
				mandatory: true,
			},
		],
	},
	{
		fieldName: 'trafficLane',
		validator: regexValidator(RegexUtils.alphaNumericWithFifteenCharacters),
	},
	{
		fieldName: 'comment',
		validator: validateReason,
		disableTnMs: true,
	},
	{
		fieldName: 'delay',
		validator: validateDelay,
		disableTnMs: true,
	},
];

export default maneuver;
