import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppEnv } from '../../../../../config';
import localForage from '../../../../../config/local-forage';
import { checkDataWithIdFromApi } from '../../../../../config/offline/dispatch-get-request';
import { Button, Loader, PopupFullPage } from '../../../../core';
import EmptySplash from '../../../../core/assets/images/empty-splash.svg';
import { APP_NAME } from '../../../../core/global-app/global-app-constant';
import { GlobalAppContext } from '../../../../core/global-app/global-app-context';
import { getDailyDriveLog } from '../../../drive-log.services';
import DlScheduleSheet from '../dl-schedule-sheet/dl-schedule-sheet';
import './dl-schedule-document-page.scss';

const DlScheduleDocumentPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const env = useAppEnv();
	const { fromApp, setFromApp } = useContext(GlobalAppContext);
	const tabClass = 'dl-schedule-document-page__tab__button';

	// states declaration
	const [isLoading, setIsLoading] = useState(true);
	const [worksheetImageUrl, setWorksheetImageUrl] = useState();
	const [selectedTab, setSelectedTab] = useState('work-sheet');
	const [currentDriveLog, setCurrentDriveLog] = useState(null);

	// params retrieved from the current worksheet page url
	const { id: driveLogId } = useParams();
	const queryParams = queryString.parse(location.search);
	const { fromApplication } = queryParams;

	// query params used for the schedule document list page url redirection
	const attachmentParams = currentDriveLog?.attachment_bigram;
	const serviceParams = currentDriveLog?.realized_service_number;
	const worksheetAction = worksheetImageUrl ? 'change' : 'load';

	// defines the text of the backButton based on the fromApp's value
	const backButtonText = fromApp === APP_NAME.DIGIPLAN ? 'back-to-driving' : 'back-to-daily-dl';

	const handleTabChange = (tab) => {
		setSelectedTab(tab);
	};

	const getBackLink = () => {
		if (fromApp === APP_NAME.DIGIPLAN) {
			return `${env?.DIGIPLAN_APP_SCHEME}://Drive`;
		}
		return `/drive-log/${driveLogId}/`;
	};

	//get the worksheet image'url
	useEffect(() => {
		const getData = async () => {
			if (driveLogId) {
				const localWorkSheet = await localForage.getItem(`dl-${driveLogId}-worksheet`);
				if (!localWorkSheet || !localWorkSheet.data) {
					const worksheetImage = await checkDataWithIdFromApi(
						'dl-worksheet-image',
						`/drive-log/${driveLogId}/work-sheet/image`,
						driveLogId,
						{ responseType: 'blob' },
					);
					if (worksheetImage && worksheetImage !== undefined)
						setWorksheetImageUrl(URL.createObjectURL(worksheetImage));
				} else {
					setWorksheetImageUrl(URL.createObjectURL(localWorkSheet.data));
				}
			}
		};
		getData();
	}, [driveLogId]);

	// get the daily drivelog in order to get the attachment_bigram and the driveLogId
	const getDailyDL = useCallback((type) => {
		getDailyDriveLog({ type })
			.then((payload) => {
				const driveLog = payload.data;
				if (driveLog?.id) {
					setCurrentDriveLog(driveLog);
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const displayWorkSheetImage = () => {
		if (worksheetImageUrl) {
			return (
				<div className={'dl-schedule-document-page__content__result'}>
					<img src={worksheetImageUrl} alt="Assigned work-sheet" />
				</div>
			);
		} else {
			return (
				<div className={'dl-schedule-document-page__content__no-result'}>
					<h1 className="no-result__title">{t('dl:dl-documents.no-assigned-schedule-document')}</h1>
					<div
						className="no-result__searching-mouse-img"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
				</div>
			);
		}
	};

	useEffect(() => {
		getDailyDL('daily');
	}, [getDailyDL]);

	useEffect(() => {
		if (fromApplication === APP_NAME.DIGIPLAN) {
			setFromApp(APP_NAME.DIGIPLAN);
		}
	}, [fromApplication, setFromApp]);

	return (
		<PopupFullPage
			className="dl-schedule-document-page"
			title={t('dl:dl-documents.my-schedule-documents')}
			backLink={getBackLink()}
		>
			<div className={'dl-schedule-document-page__content'}>
				<div className={'dl-schedule-document-page__tab__button_container'}>
					<Button
						className={`${selectedTab === 'work-sheet' ? `${tabClass}--selected` : `${tabClass}`}`}
						onClick={() => handleTabChange('work-sheet')}
					>
						{t('dl:dl-documents.work-sheet')}
					</Button>
					<Button
						className={`${
							selectedTab === 'schedule-sheet' ? `${tabClass}--selected` : `${tabClass}`
						}`}
						onClick={() => handleTabChange('schedule-sheet')}
					>
						{t('dl:dl-documents.schedule-sheet')}
					</Button>
				</div>
				<Loader isLoading={isLoading}>
					{selectedTab === 'work-sheet' && displayWorkSheetImage()}
					{selectedTab === 'schedule-sheet' && (
						<DlScheduleSheet currentDriveLog={currentDriveLog} />
					)}
					<div className="dl-schedule-document-page__actions">
						<Button
							className="dl-schedule-document-page__actions__button back button"
							type="button"
							outline
						>
							{fromApp === APP_NAME.DIGIPLAN ? (
								<a href={getBackLink()}>{t(`dl:dl-documents.${backButtonText}`)}</a>
							) : (
								<Link to={getBackLink()}>{t(`dl:dl-documents.${backButtonText}`)}</Link>
							)}
						</Button>
						<Button
							className="dl-schedule-document-page__actions__button change button"
							onClick={() =>
								navigate({
									pathname: `/drive-log/${driveLogId}/schedule-document`,
									search: `attachment=${attachmentParams.toUpperCase()}&service=${serviceParams}&action=${worksheetAction}`,
								})
							}
						>
							{t(`dl:dl-documents.${worksheetAction}-schedule-documents`)}
						</Button>
					</div>
				</Loader>
			</div>
		</PopupFullPage>
	);
};

export default DlScheduleDocumentPage;
