import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptySplash from '../../../../../../core/assets/images/empty-splash.svg';
import AddObservationWithoutMission from '../add-observation-without-mission/add-observation-without-mission';
import DlAddManoeuvre from '../dl-add-manoeuvre/dl-add-manoeuvre';
import DlAddMission from '../dl-add-mission/dl-add-mission';
import DlAddReleve from '../dl-add-releve/dl-add-releve';
import FinalizeDl from '../finalize-dl/finalize-dl';
import FinalizeMessage from '../finalize-message/finalize-message';
import NoMissionObservations from '../no-mission-observations/no-mission-observations';
import './empty-dl-list.scss';

/**
 * Renders an empty mission list on the drive log page
 * @param {object} props
 * @param {string} props.className - the className of the list
 */
const EmptyDlList = (props) => {
	const { t } = useTranslation();
	const {
		className = '',
		driveLogId,
		observations = [],
		retrieveObservationsList,
		setUncompletedDl,
		uncompletedDl,
		redirectUrl = '',
		isTnMs = false,
		isEpe = false,
	} = props;

	const getTitle = () => {
		if (isTnMs) {
			return t('dl:dl-detail-page.no-manoeuvre');
		}
		if (isEpe) {
			return t('dl:dl-detail-page.no-epe');
		}
		return t('dl:dl-detail-page.no-mission');
	};

	return (
		<div className={`empty-dl-list ${className}`}>
			<div className="empty-dl-list__content">
				{uncompletedDl && <FinalizeMessage />}
				<h1 className="empty-dl-list__content__title">{getTitle()}</h1>
				<div
					className="empty-dl-list__empty-splash"
					style={{ backgroundImage: `url(${EmptySplash})` }}
				/>
			</div>
			<div className="empty-dl-list__controls">
				{isTnMs ? (
					<DlAddManoeuvre redirectUrl={redirectUrl} />
				) : isEpe ? (
					<DlAddReleve redirectUrl={redirectUrl} />
				) : (
					<DlAddMission displayButtonForm={true} redirectUrl={redirectUrl} />
				)}
				<AddObservationWithoutMission
					isTnMs={isTnMs}
					isEpe={isEpe}
					driveLogId={driveLogId}
					redirectUrl={redirectUrl}
				/>
				<FinalizeDl
					setUncompletedDl={setUncompletedDl}
					observations={observations}
					redirectUrl={redirectUrl}
					disableComposition={isTnMs || isEpe}
				/>
			</div>
			<NoMissionObservations
				observations={observations}
				retrieveObservationsList={retrieveObservationsList}
				uncompletedDl={uncompletedDl}
				redirectUrl={redirectUrl}
				isTnMs={isTnMs}
				isEpe={isEpe}
			/>
		</div>
	);
};

EmptyDlList.propTypes = {
	className: PropTypes.string,
	driveLogId: PropTypes.string,
	observations: PropTypes.array.isRequired,
	retrieveObservationsList: PropTypes.func.isRequired,
};

export default EmptyDlList;
