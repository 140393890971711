import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from '../axios';

const useDeleteMission = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: 'deleteMission',
		mutationFn: async ({ driveLogId, id }) => {
			await http.delete(`/drive-log/${driveLogId}/mission/${id}`);
		},
		onMutate: async (variables) => {
			queryClient.setQueryData(['dlMissions', variables.driveLogId], (oldData) =>
				oldData.filter((item) => item.id !== variables.id),
			);
		},
	});
};

const useAddMission = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: 'addMission',
		mutationFn: async ({ driveLogId, values }) => {
			return http.post(`/drive-log/${driveLogId}/mission/`, values);
		},
		onMutate: async ({ driveLogId, values, action }) => {
			await queryClient.setQueryData(['dlMissions', driveLogId], (oldData) => {
				if (!oldData) return [values];
				return [...oldData, { ...values }].sort(
					(a, b) => new Date(a.hour_start) - new Date(b.hour_start),
				);
			});
			if (action) action(values.code);
		},

		onSettled: (_, variables, __) => {
			// Invalider ou mettre à jour le cache après la suppression
			queryClient.invalidateQueries(['dlMissions', variables.driveLogId]);
		},
	});
};

const useUpdateMission = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: 'updateMission',
		mutationFn: async ({ driveLogId, missionId, values }) => {
			return http.put(`/drive-log/${driveLogId}/mission/${missionId}`, { ...values });
		},
		onMutate: async ({ driveLogId, missionId, values, action }) => {
			const data = await queryClient.getQueryData(['dlMissions', driveLogId]);
			if (data && Array.isArray(data) && data.length > 0) {
				await queryClient.setQueryData(['dlMissions', driveLogId], () => {
					const val = data.map((item) => {
						if (item.id === missionId) {
							return { ...item, ...values };
						}
						return item;
					});
					return val;
				});
			}
			if (action) action();
		},
		onSettled: (_, variables, __) => {
			// Invalider ou mettre à jour le cache après la suppression
			queryClient.invalidateQueries(['dlMissions', variables.driveLogId]);
		},
	});
};

export const useMissionsQueries = () => {
	const deleteMission = useDeleteMission();
	const addMission = useAddMission();
	const updateMission = useUpdateMission();

	return { addMission, updateMission, deleteMission };
};
