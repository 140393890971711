import localForage from 'localforage';
import http from '../../config/axios';

/**
 * get worksheet image related to the drive log id
 * @param {String} driveLogId drive log uuid
 * @returns {Promise} response
 */
const getWorksheetImageByDlId = async (driveLogId) => {
	const worksheetImage = await localForage.getItem(`dl-${driveLogId}-worksheet`);
	if (!worksheetImage) {
		return null;
	}
	return worksheetImage?.data;
};

/**
 * get worksheets list based on attachment_bigram and drivelog's id
 * @returns {Promise} response
 */

const getWorkSheetList = async (attachmentBigram, driveLogId) => {
	const url = `/drive-log/${driveLogId}/work-sheet`;
	return http.get(url, { params: { attachmentBigram } });
};

/**
 * get image of the newly selected worksheet
 * @param {Object} params
 * @param {String} driveLogId
 * @returns {Promise} response
 */
const getWorksheetImage = async (params, driveLogId) => {
	const url = `drive-log/${driveLogId}/work-sheet/image`;
	const config = {
		responseType: 'blob',
		params: params,
	};
	return http.get(url, config);
};

/**
 * save temporary new worksheet
 * @param {Object} worksheet - worksheet json
 * @param {string} driveLogId - daily drive log id
 * @returns {Promise<Object>} response
 */
const saveTemporaryWorksheet = async (worksheet, driveLogId) => {
	const { offer_name: offerName, attachment_bigram: attachmentBigram, service } = worksheet;
	const params = { offerName, attachmentBigram, service };
	const worksheetImage = await getWorksheetImage(params, driveLogId);
	await localForage.setItem(`dl-${driveLogId}-worksheet`, {
		data: worksheetImage.data,
		cacheDate: new Date(),
	});
};

/**
 * get worksheet selected temporary
 * @param {String} driveLogId drive log uuid
 * @returns {Promise} response
 */
const getTemporaryWorksheet = async (driveLogId) => {
	const temporaryWorksheetImage = await localForage.getItem(`dl-${driveLogId}-worksheet-edit`);
	if (!temporaryWorksheetImage) {
		return null;
	}
	return temporaryWorksheetImage?.data;
};

export {
	getTemporaryWorksheet,
	getWorksheetImage,
	getWorksheetImageByDlId,
	getWorkSheetList,
	saveTemporaryWorksheet,
};
