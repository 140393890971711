import alarmSignal from './observation-form-structure/alarm-signal';
import authorizedPerson from './observation-form-structure/authorized-person';
import cleanliness from './observation-form-structure/cleanliness';
import comment from './observation-form-structure/comment';
import epe from './observation-form-structure/epe';
import garage from './observation-form-structure/garage';
import installationSignal from './observation-form-structure/installation-signal';
import maneuver from './observation-form-structure/maneuver';
import maneuverSignalAuthorization from './observation-form-structure/maneuver-signal-authorization';
import manualDrive from './observation-form-structure/manual-drive';
import materialSignal from './observation-form-structure/material-signal';
import orderOrNotice from './observation-form-structure/order-or-notice';
import pmr from './observation-form-structure/pmr';
import retention from './observation-form-structure/retention';
import rfn from './observation-form-structure/rfn';
import stopOnTheWay from './observation-form-structure/stop-on-the-way';
import travellerInformation from './observation-form-structure/traveller-information';
import triggerAutomaticStop from './observation-form-structure/trigger-automatic-stop';

const observationTypes = [
	{
		name: 'trigger-automatic-stop',
		label: 'rail-security',
		url: 'add-trigger-automatic-stop',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		fields: triggerAutomaticStop,
	},
	{
		name: 'move-signal-authorization',
		label: 'rail-security',
		url: 'add-move-signal-authorization',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission', 'drive-log'],
		disableTypeDriveLog: true,
		fields: maneuverSignalAuthorization,
	},
	{
		name: 'order-notice',
		label: 'rail-security',
		url: 'add-order-notice',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission', 'drive-log'],
		disableTypeDriveLog: true,
		fields: orderOrNotice,
	},
	{
		name: 'alarm-signal',
		label: 'none',
		url: 'add-alarm-signal',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		disableType: true,
		fields: alarmSignal,
	},
	{
		name: 'installation',
		label: 'signalling',
		url: 'add-installation-signalling',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission', 'drive-log'],
		disableTypeDriveLog: true,
		fields: installationSignal,
	},
	{
		name: 'cleanness',
		label: 'signalling',
		url: 'add-cleanness',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		fields: cleanliness,
	},
	{
		name: 'material',
		label: 'signalling',
		url: 'add-material',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		fields: materialSignal,
	},
	{
		name: 'train-authorized-person',
		label: 'none',
		url: 'add-person-cabin',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		fields: authorizedPerson,
	},
	{
		name: 'pmr',
		label: 'none',
		url: 'add-pmr',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		disableType: true,
		fields: pmr,
	},
	{
		name: 'garage',
		label: 'none',
		url: 'add-garage',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission', 'drive-log'],
		fields: garage,
	},
	{
		name: 'retention',
		label: 'none',
		url: 'add-retention',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		disableType: true,
		fields: retention,
	},
	{
		name: 'comment',
		label: 'none',
		url: 'add-comment',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission', 'drive-log'],
		fields: comment,
	},
	{
		name: 'rfn',
		label: 'none',
		url: 'add-rfn',
		linkedLines: ['B'],
		linkedEntities: ['drive-log'],
		disableType: true,
		fields: rfn,
	},
	{
		name: 'move',
		label: 'none',
		url: 'add-move',
		linkedLines: ['A', 'B'],
		linkedEntities: ['drive-log'],
		disableTnMs: true,
		fields: maneuver,
	},
	{
		name: 'traveller-information',
		label: 'none',
		url: 'add-traveller-information',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		disableType: true,
		fields: travellerInformation,
	},
	{
		name: 'epe',
		label: 'none',
		url: 'add-epe',
		linkedLines: ['A', 'B'],
		linkedEntities: ['drive-log'],
		disableEpe: true,
		fields: epe,
	},
	{
		name: 'stop-on-the-way',
		label: 'none',
		url: 'add-stop-on-the-way',
		linkedLines: ['A', 'B'],
		linkedEntities: ['mission'],
		fields: stopOnTheWay,
	},
	{
		name: 'manual-drive',
		label: 'none',
		url: 'manual-drive',
		linkedLines: ['A'],
		linkedEntities: ['mission'],
		disableType: true,
		fields: manualDrive,
	},
];

export default observationTypes;
