import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import localForage from '../../../../../config/local-forage';
import ReplaceExistingDlModal from '../../../components/add-sheet-line/modals/replace-existing-dl-modal/replace-existing-dl-modal';
import { createNewDriveLog, getDailyDriveLog, updateDriveLog } from '../../../drive-log.services';
import { AddSheetLine, AttachmentList, Schedule, ServiceNumber } from '../../../index';
import { getLineSheet } from '../../../sheets-line.service';

const CreateEmptyDl = () => {
	const [newDriveLog, setNewDriveLog] = useState({});
	const [activeStep, setActiveStep] = useState(0);
	const [newDriveLogId, setNewDriveLogId] = useState();
	const [displayAddSheetLine, setDisplayAddSheetLine] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = queryString.parse(location.search);
	const createNewDl = Boolean(queryParams['new-drivelog']);

	const [showReplaceExistingDlModal, setReplaceExistingDlModal] = useState(false);

	const toggleReplaceExistingDlModal = () => setReplaceExistingDlModal(!showReplaceExistingDlModal);

	const handleNavigation = (newDriveLogData) => {
		const newStep = activeStep + 1;
		const updatedDriveLog = { ...newDriveLog, ...newDriveLogData };
		const driveLogLine = updatedDriveLog?.line;
		if (newStep < 3) {
			setActiveStep(newStep);
			setNewDriveLog(updatedDriveLog);
		} else if (newStep === 3) {
			setNewDriveLog(updatedDriveLog);
			setActiveStep(newStep);
			getLineSheet({ line: driveLogLine, status: 'published' })
				.then((response) => {
					if (response) {
						const sheetLineNumber = response.fileName;
						setNewDriveLog({ ...updatedDriveLog, ...sheetLineNumber });
						if (createNewDl) {
							toggleReplaceExistingDlModal();
						} else {
							produceNewDL(updatedDriveLog, true);
						}
					} else {
						setDisplayAddSheetLine(!displayAddSheetLine);
					}
				})
				.catch((err) => {
					console.error('err : ', err);
					setDisplayAddSheetLine(!displayAddSheetLine);
					setNewDriveLog(updatedDriveLog);
				});
		} else {
			produceNewDL(updatedDriveLog, false);
		}
	};

	// Drive log creation
	const produceNewDL = (driveLogData = {}, sheetLineFound) => {
		const {
			attachmentName,
			attachmentBigram,
			line,
			realized_date_end,
			realized_date_start,
			serviceNumber,
			sheetLineNumber,
			isPsg,
			type,
		} = driveLogData;

		let formattedNewDl = {
			attachment_name: attachmentName,
			attachment_bigram: attachmentBigram,
			line_number: line,
			sheet_line_number: sheetLineNumber,
			realized_service_number: serviceNumber,
			realized_date_start: realized_date_start,
			realized_date_end: realized_date_end,
			is_psg: isPsg,
			type_log: type,
		};

		createNewDriveLog(formattedNewDl, 'empty')
			.then((res) => {
				const createdDriveLogId = res?.data;
				setNewDriveLogId(createdDriveLogId);
				const lineSheetUrl = `/line-sheet?driveLogId=${createdDriveLogId}`;
				const driveLogUrl = `/drive-log/${createdDriveLogId}`;
				const redirectionUrl = sheetLineFound ? lineSheetUrl : driveLogUrl;
				localForage.removeItem('mission-details');
				return navigate(redirectionUrl);
			})
			.catch((err) => {
				console.error(err);
				navigate('/daily-drive-log');
			});
	};

	// Updates drive log and handles redirection
	const manageLineSheets = useCallback(
		async (currentDl = {}, lineSheetFileName = '') => {
			const { id: driveLogID } = currentDl;
			// If line sheets are present, we redirect to line sheet page
			if (lineSheetFileName.length > 0) {
				const updatedDl = { ...currentDl, sheet_line_number: lineSheetFileName };
				await updateDriveLog(updatedDl, { action: 'add-sheet-line' });
				return navigate(`/line-sheet?driveLogId=${driveLogID}`);
			}
			// If no line sheets, we manually add line sheet
			return navigate(`/drive-log/${driveLogID}/modify-sheet-line-number`);
		},
		[navigate],
	);

	const getAllData = useCallback(() => {
		getDailyDriveLog({ type: 'daily' })
			.then((response) => {
				if (response) {
					const driveLog = response?.data;
					if (driveLog?.line_number) {
						const line = driveLog?.line_number;
						getLineSheet({ line, status: 'published' }).then(async (res) => {
							/**
							 * Empty drive log
							 * - if we need to make sure to create a new empty drive log - see modifying service
							 * - if no line sheets are present, we create a brand new drive log - see createNewDriveLog above
							 */
							if (createNewDl && !driveLog?.sheet_line_number) {
								return;
							}

							if (driveLog?.id && !driveLog?.sheet_line_number) {
								await manageLineSheets(driveLog, res?.fileName || '');
							}
						});
					}
				}
			})
			.catch((err) => console.error(err))
			.finally(() => {
				setIsLoading(false);
			});
	}, [createNewDl, manageLineSheets]);

	useEffect(getAllData, [getAllData]);

	return (
		<>
			{activeStep === 0 && <Schedule navigate={handleNavigation} loading={isLoading} />}
			{activeStep === 1 && (
				<AttachmentList navigate={handleNavigation} showOptionPSG={true} isEmpty={true} />
			)}
			{activeStep === 2 && <ServiceNumber navigate={handleNavigation} isEmpty={true} />}
			{activeStep === 3 && displayAddSheetLine && (
				<AddSheetLine navigate={handleNavigation} newDriveLogId={newDriveLogId} />
			)}
			{activeStep === 3 && (
				<ReplaceExistingDlModal
					showModal={showReplaceExistingDlModal}
					toggleModal={toggleReplaceExistingDlModal}
					navigate={setActiveStep}
					callback={() => produceNewDL(newDriveLog, !displayAddSheetLine)}
					isNewDl
				/>
			)}
		</>
	);
};

export default CreateEmptyDl;
